import React from "react";
import { Button, Modal } from "react-bootstrap";
import style from "./ModalAbout.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faX } from "@fortawesome/free-solid-svg-icons";
import ViewPortChecker from "../../../lib/ViewPortChecker";

const ModalAbout = (props) => {
  return (
    <Modal
      {...props}
      size="xl"
      contentClassName={style["modal-about"]}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      fullscreen={ViewPortChecker().isTabletOrMobile ? true : false}
    >
      <span onClick={() => props.onHide(false)} className={style.closeButton}>
        <FontAwesomeIcon icon={faX} fontSize={16} color="#fff" />
      </span>
      <div className={style.title}>
        <div>Tentang Kami</div>
      </div>
      <Modal.Body
        style={{
          marginTop: "-45px",
        }}
      >
        <div className={style["modal-body-content"]}>
          <ul className="tentang-kami-ul mx-md-5 p-0 fs-5">
            <li>
              Eksistensi Agriva Teknologi Nusantara sebagai System Integrator
              yang berpengalaman menangani project yang syarat dengan integrase
              yang diharapkan dapat memenuhi kebutuhan perusahaan untuk
              mengintegrasikan Web & Mobile Work Force application.
            </li>
            <li>
              Kemampuan dan pengalaman Agriva Teknologi Nusantara Automation
              mengintegrasikan multi protocol, diharapkan dapat sesuai dengan
              kebutuhan perusahaan yang ingin mengintegrasikan dengan eksisting
              System dengan new System.
            </li>
            <li>
              Kompetensi Agriva Teknologi Nusantara yang mengembangkan API,
              diharapkan akan memberikan keuntungan bagi perusahaan karena
              menyediakan integrase ke ERP Exsiting system seperti SAP, Odoo,
              Oracle, Microsoft dsb, dari existimg system customer.
            </li>
            <li>
              Kemampuan Agriva Teknologi Nusantara dalam bidang Big Data
              Analytic & Machine Learning memberikan keuntungan bagi perusahaan
              untuk dapat melakukan pengolahan data volume besar, kecepatan
              tinggi dan daya bervariasi, yang selanjutnya dapat di
              visualisasikan juga membantu decision maker dalam pengambilan
              keputusan berdasarkan analytics reporting yang ada.
            </li>
          </ul>
          {ViewPortChecker().isTabletOrMobile ? (
            <>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "40px",
                }}
              >
                <Button
                size="lg"
                  style={{
                    width: "100%",
                  }}
                  onClick={() => props.onHide(false)}
                >
                  Tutup
                </Button>
              </div>
            </>
          ) : ""}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ModalAbout;
