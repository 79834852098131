import React, { useState } from "react";
import { Fade } from "react-awesome-reveal";
import ModalAbout from "./ModalAbout";
import style from "./AboutUs.module.css";
import { Col, Row } from "react-bootstrap";
import ViewPortChecker from "../../../lib/ViewPortChecker";

const AboutUs = () => {
  const [modalShow, setModalShow] = useState(false);

  return (
    <div className={style.about} id="about">
      <Fade direction="up" triggerOnce>
        {ViewPortChecker().isTabletOrMobile ? (
          <></>
        ) : (
          <>
            <div className={style.logo}>
              <img src="assets/wallpaper/about-wp.png"></img>
            </div>
          </>
        )}

        <div className={style.content}>
          <div>Tentang Kami</div>
          <p>
            Kami lebih utama bergerak dibidang software Agriva Teknologi
            Nusantara berdiri pada tahun 2014 dan berpengalaman selama 7 tahun,
            kami dapat bekerja sama dengan client kami dengan baik, Tim kami
            memiliki hubungan yang solid, berpengalaman dan memiliki budaya
            kerja yang baik.
          </p>
          <button onClick={() => setModalShow(true)}>Selengkapnya</button>
          <ModalAbout show={modalShow} onHide={() => setModalShow(false)} />
        </div>
      </Fade>
    </div>
  );
};

export default AboutUs;
