import React, { useState } from "react";
import { Fade } from "react-awesome-reveal";
import { Col, Row, Modal, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faX } from "@fortawesome/free-solid-svg-icons";
import style from "./VisiMisiKomitmen.module.css";

const VisiMisiKomitmen = ({
  title,
  children,
  modal,
  onHide,
  full,
  modalSize,
  data
}) => {
  return (
    <Modal
      show={modal}
      onHide={onHide}
      centered
      fullscreen={full}
      size={modalSize}
    >
      <span onClick={onHide} className={style.closeButton}>
        <FontAwesomeIcon icon={faX} fontSize={16} color="#fff" />
      </span>
      <Modal.Body>
          <div
            style={{
              overflow: "auto",
              minHeight: "75vh",
            }}
          >
            {children}
          </div>
        <Button
          size="lg"
          onClick={onHide}
          style={{
            width: "100%",
          }}
        >
          Tutup
        </Button>
      </Modal.Body>
    </Modal>
  );
};

export default VisiMisiKomitmen;
