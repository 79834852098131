import React, { useEffect, useState } from "react";
import Components from "./components";
import "bootstrap/dist/css/bootstrap.min.css";
import "font-awesome/css/font-awesome.min.css";

import "./App.css";
import FloatingButton from "./components/FloatingButton";
import axios from "axios";
import DocumentMeta from "react-document-meta";

const App = () => {
  // const [ip, setIP] = useState('');

  //creating function to load ip address from the API
  const getData = async () => {
    const res = await axios.get("https://geolocation-db.com/json/");
    console.log(res.data);
    // setIP(res.data.IPv4)

    let resPost = await axios.post(
      "https://erp-mpe.com/apivisitor/visitorservice/setvisitor",
      res.data
    );

    //get loc Actual
    navigator.geolocation.getCurrentPosition(function (position) {
      console.log("Latitude is :", position.coords.latitude);
      console.log("Longitude is :", position.coords.longitude);
      const tosend = {
        IPv4: res.data.IPv4,
        actualgeo: {
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        },
      };
      axios.post(
        "https://erp-mpe.com/apivisitor/visitorservice/setvisitoractual",
        tosend
      );
    });

    let data = resPost.data;
    console.log(data);
  };

  useEffect(() => {
    //passing getData method to the lifecycle method
    getData();
  }, []);

  // meta tag
  const meta = {
    title: "Agriva - Teknologi Nusantara",
    description: "IT Solution & Software House",
    canonical: "http://loginusa.id/",
    meta: {
      charset: "utf-8",
      content: "width=device-width, initial-scale=1",
      name: {
        keywords:
          "Agriva, Agriva Teknologi Nusantara, Loginusa, Teknologi Nusantara, Software House, IT Solution, Teknologi, Nusantara",
      },
    },
  };

  return (
    <div className="main-app">
      <DocumentMeta {...meta} />
      <Components />
      <FloatingButton />
    </div>
  );
};

export default App;

//cek commit
