import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { Carousel } from "react-bootstrap";
import ViewPortChecker from "../../../lib/ViewPortChecker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLongArrowLeft } from "@fortawesome/free-solid-svg-icons";
import style from "./Projects.module.css";
import { ListPortfolio } from "./ListPortfolio";

const ModalProject = ({ onHide, show, projectSrc, btnName }) => {
  return (
    <Modal show={show} onHide={onHide} fullscreen>
      <div className={style["projects-container"]}>
        <span
          onClick={onHide}
          className={style.ModalCloseButton + " rounded-circle"}
        >
          <FontAwesomeIcon icon={faLongArrowLeft} fontSize={16} color="#fff" />
        </span>
        {projectSrc.map((data) => {
          // console.log("data: ", data);
          return (
            <div className={style["projects-list"]}>
              <h2>{data.perusahaan.toLocaleUpperCase()}</h2>
              <div>{data?.title}</div>
              <Carousel>
                {data.src.map((datas) => {
                  return ViewPortChecker().isTabletOrMobile ? (
                    datas.map((src) => {
                      return (
                        <Carousel.Item interval={10000000}>
                          <div
                            className={
                              style["carousel-projects mobile rounded"]
                            }
                          >
                            <img
                              key={src}
                              className={style["projects-image"] + " mobile"}
                              src={src}
                              alt={`${data.title}`}
                              width="100%"
                              height="auto"
                            ></img>
                          </div>
                        </Carousel.Item>
                      );
                    })
                  ) : (
                    <Carousel.Item interval={10000000}>
                      <div className={style["carousel-projects"]}>
                        {datas.map((src) => {
                          return (
                            <img
                              key={src}
                              className={style["projects-image"] + " rounded"}
                              src={src}
                              alt={`${data.title}`}
                              height="500px"
                            ></img>
                          );
                        })}
                      </div>
                    </Carousel.Item>
                  );
                })}
              </Carousel>
            </div>
          );
        })}
      </div>
    </Modal>
  );
};

export default ModalProject;
