import React, { useState, useEffect } from "react";
import { Fade } from "react-awesome-reveal";
import { Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

import style from "./Layanan.module.css";
import VisiMisiModal from "../VisiMisiKomitmen/VisiMisiModal";

const Layanan = () => {
  const [services, setServices] = useState([
    {
      title: "Mobile App",
      desc: "Program Aplikasi yang di rancang untuk berjalan pada peranti bergerak seperti ponsel atau tablet.",
      logo: "assets/layanan/android.png",
      openModal: false,
    },
    {
      title: "Web App",
      desc: "Suatu aplikasi yang dapat diakses menggunakan penjelajah web melalui suatu jaringan seperti Internet",
      logo: "assets/layanan/website.png",
      openModal: false,
    },
    {
      title: "Database Optimization",
      desc: "Optimasi database adalah strategi untuk mengurangi waktu respon sistem database.",
      logo: "assets/layanan/database.png",
      openModal: false,
    },
    {
      title: "Integration System",
      desc: "Didefinisikan dalam rekayasa sebagai proses menyatukan sub-sistem komponen kedalam satu sistem dan memastikan bahwa subsistem berfungsi bersama sebagai suatu sistem, dan dalam teknologi",
      logo: "assets/layanan/integration.png",
      openModal: false,
    },
    {
      title: "UI / UX Design",
      desc: "UI Design adalah tampilan produk yang ingin kita perlihatkan. UX berfokus pada proses pembuatan produk hingga mampu mendapatkan pengalaman kemudahan dari user",
      logo: "assets/layanan/ui-ux.png",
      openModal: false,
    },
    // {
    //   title: "Openbravo ERP Implementation",
    //   desc: "Openbravo ERP adalah sebuah aplikasi yang terintegrasi dari beberapa sistem dengan tujuan untuk membantu, mengelola dan meningkatkan kinerja perusahaan",
    //   logo: "assets/services/openbravo.png",
    // },
  ]);

  const handleOpen = (i) => {
    const data = [...services];
    data[i].openModal = true;
    setServices(data);
    // console.log("true: ", services[i]);
  };
  const handleClose = (i) => {
    const data = [...services];
    data[i].openModal = false;
    setServices(data);
    // console.log("false: ", services[i]);
  };

  return (
    <div className={style.layanan} id="layanan">
      <Fade triggerOnce>
        <div className={style.title}>Layanan Kami</div>
        <div className={style.content}>
          {services.map((service, i) => {
            return (
              <div>
                <Row className={style.contentRow} onClick={() => handleOpen(i)}>
                  <Col
                    md={1}
                    className={style.ColNumber}
                  >
                    {String(i + 1).length < 2 ? (
                      <span>0{i + 1}</span>
                    ) : (
                      <span>{i + 1}</span>
                    )}
                  </Col>
                  <Col
                    md={5}
                    className={style.ColTitle}
                  >
                    {service.title}
                  </Col>
                  <Col
                    md={5}
                    className={style.ColDesc}
                  >
                    {service.desc.replace(/^(.{25}[^\s]*).*/, "$1") + "\n"}.....
                  </Col>
                  <Col md={1} className={style.icon}>
                    <FontAwesomeIcon
                      icon={faArrowRight}
                      style={{
                        transform: "rotate(-45deg)"
                      }}
                      fontSize={24}
                    />
                  </Col>
                </Row>
                <VisiMisiModal
                  modal={services[i].openModal}
                  onHide={() => handleClose(i)}
                  modalSize="xl"
                >
                  <div className={style.modalContent}>
                    <img
                      src={service.logo}
                      alt={service.title}
                      className="img-fluid"
                    ></img>
                    <div>{service.title}</div>
                    <p>{service.desc}</p>
                  </div>
                </VisiMisiModal>
              </div>
            );
          })}
        </div>
      </Fade>
    </div>
  );
};

export default Layanan;
