import React, { useState, useEffect } from "react";
import Title from "../reused-components/Title/Title";

import style from "./Teknologi.module.css";
import TeknologiDesc from "./TeknologiDesc";
import { Carousel } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";

const Teknologi = () => {
  const technologyHeader = [
    "DATABASE",
    "BACKEND",
    "FRONTEND",
    "DATA STREAMING",
    "REPORT TOOLS",
  ];

  const assets = "/assets/teknologi/";

  const technology = [
    {
      db: [
        [
          {
            name: "PostgreSQL",
            desc: "PostgreSQL adalah sistem manajemen database relasional (RDBMS) yang  bersifat open source.  Manajemen database ini dapat  mengolah data dalam tabel yang memiliki relasi satu sama lain dan dapat digunakan secara gratis serta  bebas dimodifikasi. Dikembangkan oleh Berkeley Computer Science Department, PostgreSQL telah  menjadi database yang andal dalam  30 tahun terakhir ini. Sistem manajemen database ini memiliki  performa stabil, keamanan tinggi,  serta fitur melimpah.",
            img: assets + "postgresql.png",
            btnId: 1,
          },
          {
            name: "Oracle",
            desc: "Oracle adalah salah satu dari RDBMS atau sering disebut dengan Relation database management system buatan dari oracle corporation. Oracle database ini sering digunakan untuk membangun sebuah system yang berskala besar, seperti yang ada pada sistem perbankan. Seperti system RDBMS, oracle ini juga menggunakan SQL atau structurequery language sebagai bahasa saat mengoperasikan. Dengan menggunakan oracle ini dapat saling bertukar data dengan orang lain yang telah diberikan hak akses. ",
            img: assets + "oracledb.png",
            btnId: 1,
          },
        ],
        [
          {
            name: "MySQL",
            desc: "MySQL adalah sistem manajemen database relasional (RDBMS) open-source berbasis SQL  (Structured Query Language) yang bekerja dengan model client-server. RDBMS sendiri merupakan software  untuk membuat dan mengelola  database berdasarkan model  relasional.",
            img: assets + "mysql.png",
            btnId: 1,
          },
          {
            name: "Sap Hana",
            desc: "SAP HANA adalah sebuah platform komprehensif yang menggabungkan basis data yang kuat dengan layanan  untuk membuat aplikasi inovatif. Ini  memungkinkan proses bisnis berfungsi  secara real-time dengan memadukan  transaksi dan analisis pada satu basis  data dalam memori (in-memory). ",
            img: assets + "saphana.png",
            btnId: 1,
          },
        ],
      ],
      be: [
        [
          {
            name: "Java",
            desc: "Java. Penulisan kode program Java biasanya disimpan dalam file berekstensi java. Dengan menggunakan javac compiler, kamu bisa menyusun file berekstensi java menjadi file berekstensi class. File berekstensi class inilah yang disebut sebagai bytecode dan bisa dijalankan di seluruh Java Virtual Machine (JVM). Dilihat dari penggunaannya, sebagai bahasa pemrograman umum kamu bisa memanfaatkan Java untuk membuat berbagai bentuk aplikasi.",
            img: assets + "java.png",
            btnId: 2,
          },
          {
            name: "Node JS",
            desc: "Node.js adalah runtime environment untuk JavaScript yang bersifat open-source dan cross-platform. Dengan Node.js kita dapat menjalankan kode JavaScript di mana pun, tidak hanya terbatas pada lingkungan browser.Node.js menjalankan V8 JavaScript engine (yang juga merupakan inti dari Google Chrome) di luar browser. Ini memungkinkan Node.js memiliki performa yang tinggi.",
            img: assets + "node.png",
            btnId: 2,
          },
        ],
        [
          {
            name: "Python",
            desc: "Python telah menjadi salah satu bahasa  pemrograman paling populer di dunia dalam beberapa tahun terakhir. Bahasa  pemrograman ini digunakan dalam  segala hal mulai dari machine learning, membangun situs web, dan pengujian  software. Python dapat digunakan oleh  seorang developer dan juga non- developer.",
            img: assets + "python.png",
            btnId: 2,
          },
          {
            name: "PHP",
            desc: "PHP adalah bahasa penulisan skrip open source yang biasanya digunakan dalam pemrograman atau pengembangan website.",
            img: assets + "php.png",
            btnId: 2,
          },
        ],
      ],
      fe: [
        [
          {
            name: "React JS",
            desc: "React JS adalah library JavaScript yang biasa digunakan saat membangun UI suatu website atau aplikasi web. Jadi, React JS bisa dianggap seperti perpustakaan yang berisi berbagai kode JavaScript yang sudah tertulis (pre-written). Anda tinggal mengambil kode yang ingin Anda gunakan. Sehingga, ini membuat proses coding menjadi lebih efisien dengan framework JavaScript tersebut.",
            img: assets + "react.png",
            btnId: 3,
          },
          {
            name: "React Native",
            desc: "React Native adalah sebuah framework berbasis JavaScript yang digunakan untuk mengembangkan aplikasi mobile di dua sistem operasi secara bersamaan, yaitu Android dan iOS. React Native sendiri pertama kali diluncurkan pada tahun 2015 oleh Facebook dan bersifat open source. ",
            img: assets + "reactnative.png",
            btnId: 3,
          },
        ],
        [
          {
            name: "Vue JS",
            desc: "Vue js adalah sebuah kerangka kerja nan progresif untuk membangun antarmuka pengguna. Tidak seperti beberapa kerangka kerja monolitik yang lain, Vue dirancang dari dasar sekali agar dapat diadopsi secara bertahap. Pustaka intinya difokuskan pada layer tampilan saja, dan sangat mudah untuk diintegrasikan dengan pustaka yang lain atau dengan proyek yang sudah ada. Di sisi lain, Vue sangat mampu memberikan dan mendukung Single Page Application yang canggih ketika dikombinasikan dengan perkakas modern dan dukungan pustaka.",
            img: assets + "vue.png",
            btnId: 3,
          },
          {
            name: "Angular JS",
            desc: "AngularJS adalah framework software open-source yang digunakan untuk membuat aplikasi single-page berbasis website. Framework ini juga cukup populer bagi para developer untuk membuat menu animasi di laman web HTML. Sejarah Angular JS sendiri dulunya adalah gagasan dari seorang engineer Google, Misko Hevery dan Adam Abrons. Google merilis versi pertamanya pada tahun 2012. Sejak saat itu, AngularJS terus berkembang.",
            img: assets + "angular.png",
            btnId: 3,
          },
        ],
      ],
      ds: [
        [
          {
            name: "Apache Spark",
            desc: "Apache Spark merupakan sebuah framework atau environtment yang dapat digunakan untuk mengakses data dari berbagai sumber berbeda, kemudian mengolah data tersebut, kemudian menyimpannya kedalam penyimpanan data untuk dianalisis. Fitur yang dimiliki oleh Apache Spark memungkinkan para data engineer untuk membangun sebuah aplikasi pipa pemrosesan Big Data.",
            img: assets + "spark.png",
            btnId: 4,
          },
          {
            name: "Kubernetes",
            desc: "Kubernetes adalah platform open source untuk mengelola kumpulan kontainer dalam suatu cluster server. Platform ini pertama kali dikembangkan oleh Google dan kini dikelola oleh Cloud Native Computing Foundation (CNCF) sebagai platform manajemen kontainer yang cukup populer.",
            img: assets + "kubernetes.png",
            btnId: 4,
          },
        ],
        [
          {
            name: "Hadoop",
            desc: "Hadoop adalah framework open source yang efektif untuk menyimpan dataset dalam jumlah besar. Tidak hanya menyimpan, framework ini juga tentunya bisa memproses data mulai dari ukuran gigabyte hingga petabyte secara efisien. Meskipun data yang diolah jumlahnya besar, prosesnya lebih cepat karena menggunakan komputer yang lebih banyak. Data diolah secara terdistribusi dalam waktu yang bersamaan, sehingga kecepatan prosesnya  bisa lebih singkat.",
            img: assets + "hadoop.png",
            btnId: 4,
          },
          {
            name: "RabbitMQ",
            desc: "RabbitMQ adalah kotak pos, kantor pos, dan tukang pos. Yang bertugas menerima pesan, mengatur pesan untuk dikirimkan kemana,sekaligusmengirimkannya ke penerima. Dan umumnya pesan di RabbitMQ yang dikirimkan ke receiver digunakan untuk memicu suatu pekerjaan yang akan dilakukan oleh receiver.",
            img: assets + "rabbitmq.png",
            btnId: 4,
          },
        ],
      ],
      rt: [
        [
          {
            name: "Ireport",
            desc: "iReport adalah report designer visual yang dibangun pada JasperReport. iReport bersifat intuitif dan mudah digunakan pembangun laporan visual atau desainer untuk JasperReport dan tertulis dalam kitab Java. Sebagai alternatif, terdapat tools iReport (dengan library JasperReport) yang dapat membantu dalam pembuatan laporan.",
            img: assets + "ireport.png",
            btnId: 5,
          },
          {
            name: "Crystal Report",
            desc: "Crystal Report adalah sebuah software pembuat laporan windows-based yang bermula sejak tahun 1991. Crystal Report dapat mengintegrasi sampai dengan 12 format data source dan membuat laporan yang dinamis.",
            img: assets + "crystalreport.png",
            btnId: 5,
          },
        ],
      ],
    },
  ];

  const [navButton, setNavButton] = useState([
    {
      id: 1,
      name: "Database",
      selected: true,
    },
    {
      id: 2,
      name: "Backend",
      selected: false,
    },
    {
      id: 3,
      name: "Frontend",
      selected: false,
    },
    {
      id: 4,
      name: "Data Streaming",
      selected: false,
    },
    {
      id: 5,
      name: "Report Tools",
      selected: false,
    },
  ]);

  const [techData, setTechData] = useState(technology[0].db);

  const selectButton = (i, condition) => {
    let data = [...navButton];
    data.map((btn) => (btn.selected = false));

    data[i].selected = condition;
    setNavButton(data);

    if (data[i].name === "Database") {
      setTechData(technology[0].db);
    } else if (data[i].name === "Backend") {
      setTechData(technology[0].be);
    } else if (data[i].name === "Frontend") {
      setTechData(technology[0].fe);
    } else if (data[i].name === "Data Streaming") {
      setTechData(technology[0].ds);
    } else if (data[i].name === "Report Tools") {
      setTechData(technology[0].rt);
    }

    setIndex(0);
  };

  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  return (
    <div className={style.teknologi} id="teknologi">
      <div className={style.title}>Teknologi</div>
      <div className={style.container}>
        <div className={style.navigation}>
          {navButton.map((btn, i) => (
            <button
              onClick={() => selectButton(i, true)}
              style={{
                border: btn.selected ? "3px solid #ffffff" : "none",
              }}
            >
              {btn.name}
            </button>
          ))}
        </div>
        {useMediaQuery({ query: "(max-width: 950px)" })? 
        <Carousel
          activeIndex={index}
          onSelect={handleSelect}
          interval={null}
          wrap={false}
        >
          {techData.flat().map((x, i) => (
            <Carousel.Item interval={50000}>
              <div
                style={{
                  width: "100%",
                  minHeight: "90vh",
                  padding: "50px 0",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div
                  style={{
                    background: "#FFFFFF",
                    boxShadow:
                      "-4px -4px 30px rgba(0, 0, 0, 0.3), 4px 4px 30px rgba(0, 0, 0, 0.25)",
                    borderRadius: "20px",
                    width: "370px",
                    minHeight: "661px",
                    padding: "40px 24px",
                    textAlign: "center",
                    margin: "0 40px",
                  }}
                >
                  <img src={x.img} className="img img-fluid" alt=""></img>
                  <div className="text-start mt-4">{x.desc}</div>
                </div>
            </div>
            </Carousel.Item> 
          ))}
        </Carousel>
        :
        <Carousel
          activeIndex={index}
          onSelect={handleSelect}
          interval={null}
          wrap={false}
        >
          {techData.map((box, i) => (
            <Carousel.Item interval={50000}>
              <div
                style={{
                  width: "100%",
                  minHeight: "90vh",
                  padding: "50px 0",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {box.map((x) => (
                  <div
                    style={{
                      background: "#FFFFFF",
                      boxShadow:
                        "-4px -4px 30px rgba(0, 0, 0, 0.3), 4px 4px 30px rgba(0, 0, 0, 0.25)",
                      borderRadius: "20px",
                      width: "370px",
                      minHeight: "520px",
                      padding: "40px 24px",
                      textAlign: "center",
                      margin: "0 40px",
                    }}
                  >
                    <img src={x.img} className="img img-fluid" alt=""></img>
                    <div className="text-start mt-4">{x.desc}</div>
                  </div>
                ))}
              </div>
            </Carousel.Item> 
          ))}
        </Carousel>
          }
      </div>
    </div>
  );
};

export default Teknologi;