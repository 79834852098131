import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import style from "./Footer.module.css";
import {
  solid,
  regular,
  brands,
} from "@fortawesome/fontawesome-svg-core/import.macro"; // <-- import styles to be used
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Footer = () => {
  return (
    <div className={style.footer + " border-top shadow"}>
      <div className={style.header}>Contact</div>
      <Row>
        <Col md={5}>
          <div className={style.title}>Call: </div>
          <div className={style.content}>
            <div>Mirza Halim</div>
            <div>0813-8143-2012</div>
          </div>
          <div className={style.title}>Email: </div>
          <div className={style.content}>
            <div>mirza.halim@loginusa.id</div>
          </div>
        </Col>
        <Col>
          <div className={style.title}>Addres : </div>
          <div className={style.content}>
            <div>
              Ruko Pasar Alam No.46, Jalan Alun - Alun Selatan, RT.004/RW.016,
              Padurenan, Mustika Jaya, Vida Bekasi, Jawa Barat 16340
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Footer;
