import React, { useState, useEffect } from "react";
import ModalProject from "./ModalProject";
import style from "./Projects.module.css";
import { Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faL } from "@fortawesome/free-solid-svg-icons";

const Projects = () => {
  const listPortfolio = [
    {
      mobile: [
        {
          perusahaan: "Perumda Tirta Albantani",
          title: "Attendance App",
          src: [
            ["/assets/portfolio/13/1.svg"],
            [
              "/assets/portfolio/13/2.jpeg",
              "/assets/portfolio/13/3.jpeg",
              "/assets/portfolio/13/4.jpeg",
            ],
            ["/assets/portfolio/13/5.jpeg", "/assets/portfolio/13/6.jpeg"],
          ],
        },
        {
          perusahaan: "PT. Pupuk Indonesia",
          title: "E-PSO Mobile Applicaton",
          src: [
            [
              "/assets/portfolio/12/1.png",
              "/assets/portfolio/12/2.png",
              "/assets/portfolio/12/3.png",
            ],
            [
              "/assets/portfolio/12/4.png",
              "/assets/portfolio/12/5.png",
              "/assets/portfolio/12/6.png",
            ],
          ],
        },
        {
          perusahaan: "Partai Ummat",
          title: "Mobile Application",
          src: [
            [
              "/assets/portfolio/11/1.png",
              "/assets/portfolio/11/2.png",
              "/assets/portfolio/11/3.png",
            ],
            [
              "/assets/portfolio/11/4.png",
              "/assets/portfolio/11/5.png",
              "/assets/portfolio/11/6.png",
            ],
          ],
        },
        {
          perusahaan: "PT. ENESIS MARKETAMA INDAH",
          title: "Attendance App",
          src: [
            [
              "/assets/portfolio/5/1.png",
              "/assets/portfolio/5/2.png",
              "/assets/portfolio/5/3.png",
            ],
            [
              "/assets/portfolio/5/4.png",
              "/assets/portfolio/5/5.png",
              "/assets/portfolio/5/6.png",
            ],
          ],
        },
        {
          perusahaan: "PT. PLN",
          title: "Work Permit",
          src: [
            [
              "/assets/portfolio/4/1.png",
              "/assets/portfolio/4/2.png",
              "/assets/portfolio/4/3.png",
            ],
            ["/assets/portfolio/4/4.png", "/assets/portfolio/4/5.png"],
          ],
        },
        {
          perusahaan: "Bimbel Tiki Taka",
          title: "Diadu Mobile App",
          src: [
            [
              "/assets/portfolio/3/1.png",
              "/assets/portfolio/3/2.png",
              "/assets/portfolio/3/3.png",
            ],
            [
              "/assets/portfolio/3/4.png",
              "/assets/portfolio/3/5.png",
              "/assets/portfolio/3/6.png",
            ],
            ["/assets/portfolio/3/7.png", "/assets/portfolio/3/8.png"],
          ],
        },
        {
          perusahaan: "PT. Asmin Bara Bronang",
          title: "Value Daily Assement",
          src: [
            [
              "/assets/portfolio/2/1.png",
              "/assets/portfolio/2/2.png",
              "/assets/portfolio/2/3.png",
            ],
            ["/assets/portfolio/2/4.png", "/assets/portfolio/2/5.png"],
          ],
        },
        {
          perusahaan: "PT. Taspen Persero",
          title: "Taspen Mobile",
          src: [
            [
              "/assets/portfolio/1/1.png",
              "/assets/portfolio/1/2.png",
              "/assets/portfolio/1/3.png",
            ],
          ],
        },
      ],
      web: [
        {
          perusahaan: "PT. Pupuk Indonesia (Persero)",
          title: "Dashboard Monitoring System Kios Pupuk",
          src: [["/assets/portfolio/9/1.png"]],
        },
      ],
      dbo: [
        {
          perusahaan: "PT. Pupuk Indonesia (Persero)",
          title: "Logistik Sistem Alokasi Report",
          src: [
            ["/assets/portfolio/8/1.png"],
            ["/assets/portfolio/8/2.png"],
            ["/assets/portfolio/8/3.png"],
            ["/assets/portfolio/8/4.png"],
            ["/assets/portfolio/8/5.png"],
            ["/assets/portfolio/8/6.png"],
            ["/assets/portfolio/8/7.png"],
          ],
        },
        {
          perusahaan: "PT. ENESIS MARKETAMA INDAH",
          title: "E-Sales System",
          src: [
            ["/assets/portfolio/6/1.png"],
            ["/assets/portfolio/6/2.png"],
            ["/assets/portfolio/6/3.png"],
            ["/assets/portfolio/6/4.png"],
            ["/assets/portfolio/6/5.png"],
            ["/assets/portfolio/6/6.png"],
          ],
        },
      ],
      uiux: [],
      integration: [
        {
          perusahaan: "PT. Jaminan Pembiayaan Askrindo Syariah",
          title: "Integration System SOCP to Openbravo ERP",
          src: [
            ["/assets/portfolio/7/1.png"],
            ["/assets/portfolio/7/2.png"],
            ["/assets/portfolio/7/3.png"],
          ],
        },
        {
          perusahaan: "PT. Pancaran Darat Transport",
          title: "Custom Module Openbravo ERP (Sales Cost Analysis)",
          src: [["/assets/portfolio/10/1.png"]],
        },
        {
          perusahaan: "PT. Lima Solusi Integrasi (LIMA Consulting)",
          title: "ERP Openbravo",
          src: [
            ["/assets/portfolio/14/1.jpg"],
            ["/assets/portfolio/14/2.jpg"],
            ["/assets/portfolio/14/3.jpg"],
            ["/assets/portfolio/14/4.jpg"],
          ],
        },
      ],
    },
  ];

  const [modal, setModal] = useState([
    {
      title: "Mobile App",
      openModal: false,
    },
    {
      title: "Web App",
      openModal: false,
    },
    {
      title: "Database Optimization",
      openModal: false,
    },
    {
      title: "UI / UX Design",
      openModal: false,
    },
    {
      title: "Integration System",
      openModal: false,
    },
  ]);

  const [projectList, setProjectList] = useState([]);

  const handleOpen = (i) => {
    const data = [...modal];
    data.map((btn) => (btn.openModal = false));
    data[i].openModal = true;

    setModal(data);

    if (data[i].title == "Mobile App") {
      setProjectList(listPortfolio[0].mobile);
    } else if (data[i].title == "Web App") {
      setProjectList(listPortfolio[0].web);
    } else if (data[i].title == "Database Optimization") {
      setProjectList(listPortfolio[0].dbo);
    } else if (data[i].title == "UI / UX Design") {
      setProjectList(listPortfolio[0].uiux);
    } else if (data[i].title == "Integration System") {
      setProjectList(listPortfolio[0].integration);
    }
  };

  const handleClose = (i) => {
    setProjectList([]);
    const data = [...modal];
    data.map((btn) => (btn.openModal = false));

    setModal(data);
  };

  return (
    <div className={style.project}>
      <Row className="justify-content-center">
        {modal.map((data, i) => (
          <Col className={style.card} md="5">
            <div onClick={() => handleOpen(i, true)}>
              <div className={style.title}>{data.title}</div>
              <div>
                See Details{" "}
                <FontAwesomeIcon
                  icon={faArrowRight}
                  style={{
                    transform: "rotate(-45deg)",
                  }}
                  fontSize={24}
                />
              </div>
            </div>
            <ModalProject
              show={modal[i].openModal}
              onHide={() => handleClose(i)}
              projectSrc={projectList}
            />
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default Projects;
