import React from "react";
import { Fade } from "react-awesome-reveal";
import { Col, Row } from "react-bootstrap";
import ViewPortChecker from "../../../lib/ViewPortChecker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

import style from "./Home.module.css";

const Home = () => {
  const about = () => (window.location.href = "#about");
  const portfolio = () => (window.location.href = "#portfolio");

  return (
    <div className={style.home} id="home">
      <Fade triggerOnce>
        <div className={style.container}>
          {ViewPortChecker().isTabletOrMobile ? (
            <>
              <div className={style.brand} style={{
                textAlign: "center",
              }}>Agriva Teknologi Nusantara</div>
              <div className={style.desc}style={{
                textAlign: "center",
                display: "flex",
                justifyContent: "Center"
              }}>
                <hr width="100%" />
                <span style={{
                  position: "absolute",
                  backgroundColor: "#fff",
                  padding: "0 20px",
                  textAlign: "center"
                }}>IT Solution & Software House</span>

              </div>
              <div className={style.button}>
                <a href="#about" className="btn">
                  About Us
                </a>
                <a href="#portfolio">
                  Portfolio{" "}
                  <FontAwesomeIcon
                    icon={faArrowRight}
                    style={{
                      transform: "rotate(-45deg)",
                    }}
                  />
                </a>
              </div>
            </>
          ) : (
            <>
              <div className={style.brand}>
                Agriva <br />
                Teknologi Nusantara
              </div>
              <div className={style.desc}>
                <hr width="164px" />
                <span>IT Solution & Software House</span>
              </div>
              <div className={style.button}>
                <a href="#about" className="btn">
                  About Us
                </a>
                <a href="#portfolio">
                  Portfolio{" "}
                  <FontAwesomeIcon
                    icon={faArrowRight}
                    style={{
                      transform: "rotate(-45deg)",
                    }}
                  />
                </a>
              </div>
            </>
          )}
        </div>
      </Fade>
    </div>
  );
};

export default Home;
