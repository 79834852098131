import React, { useEffect, useState } from "react";
import { Fade } from "react-awesome-reveal";
import { Col, Row } from "react-bootstrap";
import visimisikomitmen from "./VisiMisiKomitmenText";
import VisiMisiModal from "./VisiMisiModal";
import style from "./VisiMisiKomitmen.module.css";

const VisiMisiKomitmen = () => {
  const visi = visimisikomitmen.visi;
  const misi = visimisikomitmen.misi;
  const komitmen = visimisikomitmen.komitmen;

  const numbers = [4, 9, 16, 25];

  const [showVisi, setShowVisi] = useState(false);
  const [showMisi, setShowMisi] = useState(false);
  const [showKomitmen, setShowKomitmen] = useState(false);

  const handleVisi = () => setShowVisi(true);
  const handleMisi = () => setShowMisi(true);
  const handleKomitmen = () => setShowKomitmen(true);

  return (
    <div className={style["visi-misi-komitmen"]} id={"visi-misi-komitmen"}>
      <Fade triggerOnce>
        <Row className={style.className}>
          <Col md="4" className={style.column}>
            <div className={style.box} onClick={handleVisi}>
              VISI
            </div>
            <VisiMisiModal
              modal={showVisi}
              onHide={() => setShowVisi(false)}
              key={1}
            >
              <h3>{visi.title}</h3>
              {visi.desc}
            </VisiMisiModal>
          </Col>
          <Col md="4" className={style.column}>
            <div className={style.box} onClick={handleMisi}>
              MISI
            </div>
            <VisiMisiModal
              modal={showMisi}
              onHide={() => setShowMisi(false)}
              key={2}
            >
              <h3>{misi.title}</h3>
              <ul>
                {misi.desc.map((x) => (
                  <li>{x}</li>
                ))}
              </ul>
            </VisiMisiModal>
          </Col>
          <Col md="4" className={style.column}>
            <div className={style.box} onClick={handleKomitmen}>
              KOMITMEN
            </div>
            <VisiMisiModal
              key={3}
              modal={showKomitmen}
              onHide={() => setShowKomitmen(false)}
            >
              <h3>{komitmen.title}</h3>
              <ul>
                {komitmen.desc.map((x) => (
                  <li>{x}</li>
                ))}
              </ul>
            </VisiMisiModal>
          </Col>
        </Row>
      </Fade>
    </div>
  );
};

export default VisiMisiKomitmen;
