import React, { useState, useEffect } from "react";
import { Navbar, Nav } from "react-bootstrap";

import "./NavigationBar.css";

const NavigationBar = () => {
  const [navbar, setNavbar] = useState(false);

  const listenScrollEvent = (e) => {
    if (window.scrollY > 200) {
      setNavbar(true);
    } else if (window.scrollY < 200) {
      setNavbar(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", listenScrollEvent);
    return () => {
      window.addEventListener("scroll", listenScrollEvent);
    };
  }, []);

  const [selectNavbar, setSelectNavbar] = useState([
    {
      id: "about",
      name: "About",
      selected: false,
    },
    {
      id: "visi-misi-komitmen",
      name: "Visi Misi & Komitmen",
      selected: false,
    },
    {
      id: "layanan",
      name: "Layanan",
      selected: false,
    },
    {
      id: "teknologi",
      name: "Teknologi",
      selected: false,
    },
    {
      id: "portfolio",
      name: "Portfolio",
      selected: false,
    },
  ]);

  const handleNavbar = (i) => {
    let data = [...selectNavbar];
    data.map((btn) => (btn.selected = false));

    data[i].selected = true;
    setSelectNavbar(data);
  };

  return (
    <Navbar
      className={`navbar ${navbar && "nav-color shadow"}`}
      expand="lg"
      fixed="top"
    >
      <Navbar.Brand className="nav-brand" href="#home">
        <img src="assets/logo/logo.png" alt="loginusa logo" width={104}></img>
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="ms-auto">
          {selectNavbar.map((nav, i) => (
            <Nav.Link
              onClick={() => handleNavbar(i)}
              className={`nav-link ${nav.selected === true ? "selected" : ""}`}
              href={`#${nav.id}`}
            >
              <div>{nav.name}</div>
            </Nav.Link>
          ))}
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default NavigationBar;
